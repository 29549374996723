import React from "react"
import CreateProfileForm from "../../components/forms/createprofile"
import Layout from "../../components/layouts/layout"

const ResearchEstimate = () => {
	return (
		<Layout title="Get Estimate">
			<CreateProfileForm
				source="/research/estimate"
				roles="Client"
			/>
    </Layout>
  )
}

export default ResearchEstimate